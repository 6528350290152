<template>
  <CRow class="justify-content-center m-0 pt-3">
    <CCol sm="12" lg="3">
      <CInput
        size="sm"
        v-uppercase
        :placeholder="$t('label.activity')"
        addLabelClasses="required text-right"
        :label="$t('label.activity')"
        :horizontal="{label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
        v-model="vForm.GeneralData.PortActivityName.$model"
        :is-valid="hasError(vForm.GeneralData.PortActivityName)"
        :invalid-feedback="errorMessage(vForm.GeneralData.PortActivityName)"
        disabled
      />
    </CCol>
    <CCol sm="12" lg="3">
      <div class="form-group form-row">
        <label class="text-right col-sm-12 col-lg-5 required col-form-label-sm mb-0">{{`${$t('label.quantity')}`}}</label>
        <div class="input-group col-sm-12 col-lg-7 input-group-sm">
          <money
            size="sm"
            v-bind="QuantityMeasure" 
            :class="!vForm.GeneralData.Quantity.$dirty ? 'form-control' : (vForm.GeneralData.Quantity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
            v-model.trim="vForm.GeneralData.Quantity.$model"
            value="0"
            maxlength= "12"
            disabled
          >
          </money>
          <div class="invalid-feedback" v-if="vForm.GeneralData.Quantity.$error">
            {{ errorMessage(vForm.GeneralData.Quantity) }}
          </div>
        </div>
      </div>
    </CCol>
    <CCol sm="12" lg="3">
      <div class="form-group form-row">
        <label class="text-right col-sm-12 col-lg-5 required col-form-label-sm mb-0">{{`${$t('label.weight')} (KGM)`}}</label>
        <div class="input-group col-sm-12 col-lg-7 input-group-sm">
          <money
            size="sm"
            v-bind="measure" 
            :class="!vForm.GeneralData.Weight.$dirty ? 'form-control' : (vForm.GeneralData.Weight.$error ? 'form-control is-invalid' : 'form-control is-valid')"
            v-model.trim="vForm.GeneralData.Weight.$model"
            value="0"
            maxlength= "12"
            :disabled="form.GeneralData.ReceptionList.length==0"
          >
          </money>
          <div class="invalid-feedback" v-if="vForm.GeneralData.Weight.$error">
            {{ errorMessage(vForm.GeneralData.Weight) }}
          </div>
        </div>
      </div>
    </CCol>
    <CCol sm="12" lg="3">
      <div class="form-group form-row">
        <label class="text-right col-sm-12 col-lg-5 col-form-label-sm mb-0">{{`${$t('label.volume')} (M³)`}}</label>
        <div class="input-group col-sm-12 col-lg-7 input-group-sm">
          <money
            size="sm"
            v-bind="measure" 
            :class="!vForm.GeneralData.Volumen.$dirty ? 'form-control' : (vForm.GeneralData.Volumen.$error ? 'form-control is-invalid' : 'form-control is-valid')"
            v-model.trim="vForm.GeneralData.Volumen.$model"
            value="0"
            maxlength= "12"
            :disabled="form.GeneralData.ReceptionList.length==0"
          >
          </money>
          <div class="invalid-feedback" v-if="vForm.GeneralData.Volumen.$error">
            {{ errorMessage(vForm.GeneralData.Volumen) }}
          </div>
        </div>
      </div>
    </CCol>

    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedBlList"
        :fields="Blfields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
      >
        <template #Status="{ item }">
          <td class="text-center">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>
        <template #Quantity="{ item }">
          <td class="text-center">
            {{ formatNumber(item.Quantity, 2) }}
          </td>
        </template>
        <template #Weigth="{ item }">
          <td class="text-center">
            {{ formatNumber(item.Weigth, 2) }}
          </td>
        </template>
        <template #Volumen="{ item }">
          <td class="text-center">
            {{ formatNumber(item.Volumen, 2) }}
          </td>
        </template>
      </dataTableExtended>
      <!-- <div class="text-invalid-feedback text-center" v-if="vForm.PackagingList.$error">
        {{ $t('validation.RequiredDetail') }}
      </div> -->
    </CCol>
  </CRow>
</template>
<script>
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import UpperCase from '@/_validations/uppercase-directive';
import { Money } from "v-money";
import { DateFormater, NumberFormater } from '@/_helpers/funciones';

function data() {
  return {
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },
    QuantityMeasure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 0,
      masked: false,
      maxlength: 6,
    },
  };
}

//methods
function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

//computed
function computedBlList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);

  return this.form?.GeneralData?.ReceptionList?.map(e => {
    let packagings = [];
    let serials = [];

    e?.DetailJson?.map((el) => {
      packagings.push(`${el[`PackagingName${_lang}`]}(${el.Quantity})`)

      el?.SerialJson?.map((el) => {
        serials.push(`${el[`Serial`]}`)
      })
    })
    return {
      ...e,
      PackagingName: packagings.join(', '),
      Serial: serials.length != 0 ? serials.join(', ') : 'N/A',
      ReceptionDate: e.ReceptionDate ? DateFormater.formatDateTimeWithSlash(e.ReceptionDate) : 'N/A',
    }
  });
}

function Blfields(){
  return [
    { key: 'Nro', label: '#', _style: 'width:1%: min-width:45px;', _classes: 'text-center', filter: false },
    { key: 'YardName', label: this.$t('label.yard'),  _classes: 'text-uppercase text-center', _style: 'min-width:180px;'},
    { key: 'PackagingName', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;'},
    { key: 'Serial', label: this.$t('label.serial'),  _classes: 'text-uppercase text-center', _style: 'min-width:180px;'},
    { key: 'Quantity', label: this.$t('label.quantity'), _classes: 'text-center', _style: 'min-width:160px;'},
    { key: 'Weigth', label: `${this.$t('label.weight')} (KGM)`, _style:'min-width:180px;', _classes: 'text-center'},
    { key: 'Volumen', label: `${this.$t('label.volume')} (M³)`, _style:'min-width:180px;', _classes:"text-center"},
    { key: 'ReceptionDate', label: this.$t('label.ReceptionDate'), _style:'min-width:170px;', _classes:"text-center"},
    { key: 'DeviceCod', label: this.$t('label.device'),  _classes: 'text-uppercase text-center', _style: 'min-width:160px;'},
    { key: 'TransaLogin',label: this.$t('label.user'), _classes: 'text-uppercase text-center', _style:'min-width:160px;' },
  ];
}

export default {
  name: 'generalData',
  components: {
    Money
  },
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    vForm: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  mixins: [
    GeneralMixin,
    ModalMixin,
  ],
  directives: UpperCase,
  methods: {
    formatNumber,
  },
  computed: {
    computedBlList,
    Blfields,
  },
  watch: {

  }
};
</script>